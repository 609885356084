<template>
	<div :style="headBg" style="padding: 15px;">
		<div class="head-user-content">
			<img class="head-logo-icon" :src="require('../assets/login/ambc-logo2-color.png')"/>
			<div class="head-user-info-content" style="margin-left: 10px;">
				<label class="head-user-id-lab" v-text="infoData.info.username"></label>
				<label class="head-green-lab">{{$t('home.referrerId')}}：
					<span v-text="infoData.info.parentAccount"></span>
				</label>
				<label v-if="infoData.info.lv === 1" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv1')}}</label>
				<label v-if="infoData.info.lv === 2" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv2')}}</label>
				<label v-if="infoData.info.lv === 3" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv3')}}</label>
				<label v-if="infoData.info.lv === 4" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv4')}}</label>
				<label v-if="infoData.info.lv === 5" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv5')}}</label>
			</div>
			<i v-if="infoData.info.state === 1" class="fa fa-exclamation-triangle text-danger" style="color: #FF0000; margin-left: 30px; font-size: 22px;" aria-hidden="true"></i>
			<i v-else-if="infoData.info.state === 2" class="fa fa-check-circle text-success" style="color: #009245; margin-left: 30px; font-size: 32px;" aria-hidden="true"></i>
		</div>
	</div>
</template>

<script>
	import "@/styles/head.css"
	
	export default {
		name: 'HomeHead',
		props: {
			infoData: {
				type: Object,
				default: () => {
					return {
						info: {}
					}
				}
			}
			
		},
		data:() => ({
			headBg : {
				height: "200px",
				backgroundImage: "url(" + require("../assets/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			},
		}),
		methods: {}
	}
</script>


