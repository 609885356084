<template>
	<div class="page-tabbar">
		<div class="page-wrap">
			<mt-tab-container class="page-tabbar-container" v-model="selected">
				<mt-tab-container-item id="tab0">
					<Home :infoData="infoData"></Home>
				</mt-tab-container-item>
				<mt-tab-container-item id="tab1">
					<Fund :infoData="infoData"></Fund>
				</mt-tab-container-item>
				<mt-tab-container-item id="tab2">
					<Mall></Mall>
				</mt-tab-container-item>
				<mt-tab-container-item id="tab3">
					<Setting :infoData="infoData"></Setting>
				</mt-tab-container-item>
			</mt-tab-container>
		</div>
		<mt-tabbar v-model="selected" fixed style="max-width: 600px; margin: auto;">
			<mt-tab-item id="tab0" style="color: #333333;">
				<img slot="icon" src="../assets/tab/home.png">
					{{$t('tab.home')}}
			</mt-tab-item>
			<mt-tab-item id="tab1" style="color: #333333;">
				<img slot="icon" src="../assets/tab/money.png">
					{{$t('tab.assets')}}
			</mt-tab-item>
<!-- 			<mt-tab-item id="tab2" style="color: #333333;">
				<img slot="icon" src="../assets/tab/mall.png">
					商城
			</mt-tab-item> -->
			<mt-tab-item id="tab3" style="color: #333333;">
				<img slot="icon" src="../assets/tab/setting.png">
					{{$t('tab.setting')}}
			</mt-tab-item>
		</mt-tabbar>
	</div>
</template>

<script>
	import Home from './Home.vue'
	import Fund from './Fund.vue'
	import Setting from './Setting.vue'
	import Mall from './mall/GoodsList.vue'
	import { Indicator, MessageBox } from 'mint-ui';
	
	export default {
		name: 'Main',
		components: {
			Home,
			Fund,
			Setting,
			Mall
		},
		created() {
			MessageBox(this.$i18n.t("common.tips"), '为了配合AMBG生态链ARC20底层的USDT启用规范，所有会员的ERC20充值地址将会同时进行更换，并且启用全新的充值钱包地址。');
		},
		data() {
			return {			
				selected : sessionStorage.getItem('selected') ? JSON.parse(sessionStorage.getItem('selected')) : 'tab0',
				infoData: {
					wallet : JSON.parse(localStorage.getItem('walletInfo')) ? JSON.parse(localStorage.getItem('walletInfo')) : {},
					info : JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : {}
				}
			}
		},
		mounted:function () {
			if(this.$route.query.selected) {
				this.selected = this.$route.query.selected ? this.$route.query.selected : 'tab0';
			}
			
			let refreshFlag = sessionStorage.getItem('refreshInfo');
			if(refreshFlag === '1') {
				this.refreshInfo();
			}
		},
		watch: {
			selected (val) {
				// 标签栏变化，把selected的值存到sessionStorage，保存当前值
				sessionStorage.setItem('selected', JSON.stringify(val))
			}
		},
		methods: {
			refreshInfo() {
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				// 请求个人信息
				this.$request.userInfo({}).then((infoData) => {
					Indicator.close();
					sessionStorage.setItem('refreshInfo', '0');
					this.infoData = infoData
					this.$store.commit('updateUserInfo', infoData);
				});
			}
		}
	}
</script>


<style>
	.page-tabbar {
		overflow: hidden;
		height: 100vh;
		background-color: #f2f2f2;
	}
	.page-wrap {
		overflow: auto;
		height: 100%;
		width: 100%;
		padding-bottom: 64px;
	}
	..mint-tabbar {
		max-width: 600px;
		margin: auto;
	}
</style>
