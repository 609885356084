<template>
	<div :style="headBg" style="padding: 15px;">
		<div class="head-user-content">
			<img class="head-logo-icon" :src="require('../assets/login/ambc-logo2-color.png')"/>
			<div class="head-user-info-content" style="margin-left: 10px;">
				<label class="head-user-id-lab" v-text="infoData.info.username"></label>
				<label class="head-green-lab">{{$t('home.referrerId')}}：
					<span v-text="infoData.info.parentAccount"></span>
				</label>
				<label v-if="infoData.info.lv === 1" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv1')}}</label>
				<label v-if="infoData.info.lv === 2" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv2')}}</label>
				<label v-if="infoData.info.lv === 3" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv3')}}</label>
				<label v-if="infoData.info.lv === 4" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv4')}}</label>
				<label v-if="infoData.info.lv === 5" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv5')}}</label>
			</div>
			
			<button @click="refresh" style="margin-left: 30px;" >
				<i class="fa fa-refresh" style="color: #FF0000; font-size: 22px;" aria-hidden="true"></i>
			</button>
		</div>
		
		<div class="head-business-content">
			<mt-button @click="goPage('History')" style="width: 30%; font-size: 14px; height: 36px;" type="default" size="normal">{{$t('assets.detail')}}</mt-button>
			<mt-button @click="goTransfer()" style="width: 30%; font-size: 14px; height: 36px;" type="default" size="normal">{{$t('assets.trasfer')}}</mt-button>
			<mt-button @click="goPage('Buy')" style="width: 30%; font-size: 14px; height: 36px;" type="default" size="normal">{{$t('home.buy')}}</mt-button>
		</div>
		
		<div class="head-business-content">
			<mt-button @click="goPage('BuyBitMall')" style="width: 97%; font-size: 14px; height: 36px;" type="default" size="normal">{{$t('assets.buyBitMall')}}</mt-button>
		</div>
		
		<!-- <div class="head-business-content">
			<router-link to="/history?selected=1" class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.wallet.usdt"></label>
				<label class="head-green-lab">{{$t('common.usdt')}}</label>
			</router-link>
			<div style="width: 1px; height: 80%; background-color: #FFFFFF;"></div>
			<router-link to="/history?selected=8" class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.wallet.pbt"></label>
				<label class="head-green-lab">{{$t('common.pbt')}}</label>
			</router-link>
			<div style="width: 1px; height: 80%; background-color: #FFFFFF;"></div>
			<router-link to="/history?selected=2" class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.wallet.ambc"></label>
				<label class="head-green-lab">{{$t('common.ambc')}}</label>
			</router-link>
			<div style="width: 1px; height: 80%; background-color: #FFFFFF;"></div>
			<router-link to="/history?selected=3" class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.wallet.nasdaq"></label>
				<label class="head-green-lab">{{$t('common.nasdaq')}}</label>
			</router-link>
		</div>
		<div style="width: 100%; height: 1px; background-color: #FFFFFF; margin-top: 10px;"></div>
		<div class="head-business-content">
			<router-link to="/history?selected=4" class="head-business-info-content">
				<label class="head-red-lab" v-text="(infoData.wallet.musd + infoData.wallet.rusdm).toFixed(4)"></label>
				<label class="head-green-lab">{{$t('common.usdm')}}</label>
			</router-link>
			<div style="width: 1px; height: 80%; background-color: #FFFFFF;"></div>
			<router-link to="/history?selected=5" class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.wallet.ambg"></label>
				<label class="head-green-lab">{{$t('common.ambg')}}</label>
			</router-link>
			<div style="width: 1px; height: 80%; background-color: #FFFFFF;"></div>
			<router-link to="/history?selected=6" class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.wallet.hk"></label>
				<label class="head-green-lab">{{$t('common.hk')}}</label>
			</router-link>
			<div style="width: 1px; height: 80%; background-color: #FFFFFF;"></div>
			<router-link to="/history?selected=9" class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.wallet.wdt"></label>
				<label class="head-green-lab">{{$t('common.wdt')}}</label>
			</router-link>
		</div> -->
	</div>
</template>

<script>
	import "@/styles/head.css"
	
	export default {
		name: 'HomeHead',
		props: {
			infoData: {
				type: Object,
				default: () => {
					return {
						wallet: {},
						info:{}
					}
				}
			}
			
		},
		data:() => ({
			headBg : {
				height: "200px",
				backgroundImage: "url(" + require("../assets/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			},
		}),
		methods: {
			refresh() {
				this.$parent.refreshWallet();
			},
			
			goPage(pageName) {
				this.$router.push({
					name: pageName
				})
			},
			
			goTransfer() {
				this.$parent.goTransfer();
				
			},
		}
	}
</script>


