<template>
	<div class="bg-content">
		<FundHead :infoData="infoData" style="height: 210px; margin-bottom: 10px;"></FundHead>
		
		<router-link to="/history?selected=1" class="white-row-content fund-func-content">
			<div class="row-content">
				<label class="fund-func-title-lab">{{$t('common.usdt')}}</label>
			</div>
			<div>
				<label class="fund-func-lab" style="margin-right: 10px;" v-text="infoData.wallet.usdt"></label>
				<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
			</div>
		</router-link>
		
		<router-link to="/history?selected=8" class="white-row-content fund-func-content">
			<div class="row-content">
				<label class="fund-func-title-lab">{{$t('common.pbt')}}</label>
			</div>
			<div>
				<label class="fund-func-lab" style="margin-right: 10px;" v-text="(infoData.wallet.pbt + infoData.wallet.rpbt).toFixed(4)"></label>
				<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
			</div>
		</router-link>
		
		<router-link to="/history?selected=2" class="white-row-content fund-func-content">
			<div class="row-content">
				<label class="fund-func-title-lab">{{$t('common.ambc')}}</label>
			</div>
			<div>
				<label class="fund-func-lab" style="margin-right: 10px;" v-text="infoData.wallet.ambc"></label>
				<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
			</div>
		</router-link>

		<router-link to="/history?selected=3" class="white-row-content fund-func-content">
			<div class="row-content">
				<label class="fund-func-title-lab">{{$t('common.nasdaq')}}</label>
			</div>
			<div>
				<label class="fund-func-lab" style="margin-right: 10px;" v-text="infoData.wallet.nasdaq"></label>
				<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
			</div>
		</router-link>
		
		<router-link v-show="false" to="/history?selected=4" class="white-row-content fund-func-content">
			<div class="row-content">
				<label class="fund-func-title-lab">{{$t('common.usdm')}}</label>
			</div>
			<div>
				<label class="fund-func-lab" style="margin-right: 10px;" v-text="(infoData.wallet.musd + infoData.wallet.rusdm).toFixed(4)"></label>
				<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
			</div>
		</router-link>
		
		<router-link to="/history?selected=5" class="white-row-content fund-func-content">
			<div class="row-content">
				<label class="fund-func-title-lab">{{$t('common.ambg')}}</label>
			</div>
			<div>
				<label class="fund-func-lab" style="margin-right: 10px;" v-text="infoData.wallet.ambg"></label>
				<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
			</div>
		</router-link>
		
		<router-link to="/history?selected=6" class="white-row-content fund-func-content">
			<div class="row-content">
				<label class="fund-func-title-lab">{{$t('common.hk')}}</label>
			</div>
			<div>
				<label class="fund-func-lab" style="margin-right: 10px;" v-text="infoData.wallet.hk"></label>
				<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
			</div>
		</router-link>
		
		<router-link to="/history?selected=7" class="white-row-content fund-func-content">
			<div class="row-content">
				<label class="fund-func-title-lab">{{$t('common.wdt')}}</label>
			</div>
			<div>
				<label class="fund-func-lab" style="margin-right: 10px;" v-text="(infoData.wallet.wdt + infoData.wallet.lwdt).toFixed(4)"></label>
				<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
			</div>
		</router-link>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="messageTipVisible" popup-transition="popup-fade">
			<AuthTip></AuthTip>
		</mt-popup>
	</div>
</template>

<script>
	import FundHead from '../components/FundHead.vue';
	import AuthTip from '../components/AuthTip.vue'
	
	export default {
		name: 'Fund',
		props: {
			infoData: {
				type: Object,
				default: () => {
					return {
						wallet: {},
						info:{}
					}
				}
			}
			
		},
		components: {
			FundHead,
			AuthTip
		},
		data() {
			return {			
				usdt : '',
				messageTipVisible: false
			}
		},
		methods: {
			refreshWallet() {
				this.$parent.$parent.$parent.refreshInfo();
			},
			
			goTransfer() {
				// 判断是否实名认证
				let state = JSON.parse(localStorage.getItem('userInfo')).state;
				if(state == 1) {
					// 未完成实名认证
					this.$data.messageTipVisible = true;
				}else if(state == 2) {
					// 已完成实名认证
					this.$router.push({
						name: 'Transfer'
					})
				}else{
					// 账号异常
				}
			},
			
			closePop() {
				this.$data.messageTipVisible = false;
			},
			
			goAuth() {
				this.$router.push({
					name: 'Auth'
				})
			},
		}
	}
</script>

<style>
	.fund-func-content {
		height: 70px; 
		justify-content: space-between; 
		margin-top: 1px;
	}
	
	.fund-func-img {
		width: auto; 
		height: 30px;
	}
	
	.fund-func-title-lab {
		font-weight: bold; 
		font-size: 16px; 
		color: #888888; 
		margin-left: 10px;
	}
	
	.fund-func-lab {
		font-size: 16px; 
		color: #888888; 
		margin-left: 10px;
	}
</style>
