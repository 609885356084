<template>
	<div class="tip-content">
		<div class="tip-info-icon-content">
			<span class="tip-info-text">i</span>
		</div>
		
		<label class="tip-title">{{$t('common.tips')}}</label>
		<label class="tip-sub-title">{{$t('tips.withdrawTip')}}</label>
		
		<div class="row-content tip-btn-content" style="justify-content: space-between;">
			<mt-button @click="closePop" type="danger" style="width: 80px;">{{$t('common.cancel')}}</mt-button>
			<mt-button @click="goAddAddress" type="primary" style="width: 80px;">{{$t('common.ok')}}</mt-button>
		</div>
	</div>
</template>

<script>
	import "@/styles/tips.css"
	
	export default {
		name: 'AuthTip',
		methods: {
			closePop() {
				this.$parent.$parent.closePop();
			},
			goAddAddress() {
				this.$parent.$parent.goAddAddress();
			}
		}
	}
</script>

<style>
</style>
