<template>
	<div class="bg-content">
		<HomeHead :infoData="infoData" style="height: 200px;"></HomeHead>
		<router-link to="/invite">
			<div class="white-row-content">
				<div class="invate-text-content">
					<label class="invate-title">{{$t('home.invite')}}
						<span style="color: #6610f2;">{{$t('home.commission')}}</span>
					</label>
					<label class="invate-sub-title">{{$t('home.share')}}</label>
				</div>
				<div style="width: 50%;">
					<img style="width: 100%; height: auto;" :src="require('../assets/home/gift.png')"/>
				</div>
			</div>
		</router-link>
		<div class="home-func-content" style="margin-top: 10px;">
			<router-link to="/notice" class="home-func">
				<img class="home-fun-img" :src="require('../assets/home/notice.png')"/>
				<label class="home-fun-lab">{{$t('home.notice')}}</label>
			</router-link>
			<div style="width: 1px; height: 100%; background-color: #f2f2f2;"></div>
			<router-link to="/buy" class="home-func">
				<img class="home-fun-img" :src="require('../assets/home/invest-icon.png')"/>
				<label class="home-fun-lab">{{$t('home.buy')}}</label>
			</router-link>			
		</div>
		<div v-show="true" class="home-func-content" style="margin-top: 1px;">
			<router-link to="/recharge" class="home-func">
				<img class="home-fun-img" :src="require('../assets/home/topup.png')"/>
				<label class="home-fun-lab">{{$t('home.topup')}}</label>
			</router-link>
			<div style="width: 1px; height: 100%; background-color: #f2f2f2;"></div>
			<div @click="goWithDrawa" class="home-func">
				<img class="home-fun-img" :src="require('../assets/home/withdrawa.png')"/>
				<label class="home-fun-lab">{{$t('home.withdraw')}}</label>
			</div>			
		</div>
				
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="messageTipVisible" popup-transition="popup-fade">
			<AuthTip></AuthTip>
		</mt-popup>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="withdrawTipVisible" popup-transition="popup-fade">
			<WithdrawTip></WithdrawTip>
		</mt-popup>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import HomeHead from '../components/HomeHead.vue'
	import AuthTip from '../components/AuthTip.vue'
	import WithdrawTip from '../components/WithdrawTip.vue'
	
	export default {
		name: 'Home',
		props: {
			infoData: {
				type: Object,
				default: () => {
					return {
						info:{}
					}
				}
			}
			
		},
		components: {
			HomeHead,
			AuthTip,
			WithdrawTip
		},
		data:() => ({
			userInfo: {},
			messageTipVisible: false,
			withdrawTipVisible: false
		}),
		methods: {
			closePop() {
				this.$data.messageTipVisible = false;
				this.$data.withdrawTipVisible = false;
			},
			goAuth() {
				this.$router.push({
					name: 'AuthStatus'
				})
			},
			goAddAddress() {
				this.$router.push({
					name: 'AddressAdd'
				})
			},
			goWithDrawa() {				
				// 判断是否实名认证
				let state = JSON.parse(localStorage.getItem('userInfo')).state;
				if(state == 1) {
					// 未完成实名认证
					this.$data.messageTipVisible = true;
				}else if(state == 2) {
					// 已完成实名认证
					this.$router.push({
						name: 'Withdraw'
					});
					
					// let address = JSON.parse(localStorage.getItem('walletInfo')).withdrawAddress;
					
					// if(address == null || address == '') {
					// 	this.$data.withdrawTipVisible = true;
					// }else{
					// 	this.$router.push({
					// 		name: 'Withdraw'
					// 	});
					// }
				}else{
					// 账号异常
				}
			}
		}
	}
</script>


<style>
	.invate-text-content {
		width: 50%; 
		display: flex; 
		flex-direction: column;
	}
	
	.invate-title {
		color: #ff0000; 
		font-weight:bold; 
		font-size: 20px
	}
	
	.invate-sub-title {
		color: #009245;
		margin-top: 5px;
	}
	
	.home-func-content {
		height: 100px; 
		display: flex; 
		flex-direction: row; 
		align-items: center; 
		padding: 15px; 
		background-color: #FFFFFF;
	}
	
	.home-func {
		width: 50%; 
		display: flex; 
		flex-direction: row; 
		align-items: center; 
		justify-content: center;
	}
	
	.home-fun-img {
		width: 60px; 
		height: 50px;
	}
	
	.home-fun-lab {
		font-weight: bold; 
		font-size: 18px; 
		color: #888888; 
		margin-left: 10px;
	}
	
</style>
