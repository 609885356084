<template>
	<div :style="headBg" style="padding: 15px;">
		<div class="head-user-content">
			<img class="head-logo-icon" :src="require('../assets/login/ambc-logo2-color.png')"/>
			<div class="head-user-info-content" style="margin-left: 10px;">
				<label class="head-user-id-lab" v-text="infoData.info.username"></label>
				<label class="head-green-lab">{{$t('home.referrerId')}}：
					<span v-text="infoData.info.parentAccount"></span>
				</label>
				<label v-if="infoData.info.lv === 1" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv1')}}</label>
				<label v-if="infoData.info.lv === 2" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv2')}}</label>
				<label v-if="infoData.info.lv === 3" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv3')}}</label>
				<label v-if="infoData.info.lv === 4" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv4')}}</label>
				<label v-if="infoData.info.lv === 5" class="head-green-lab">{{$t('home.mark')}}：{{$t('home.mark_lv5')}}</label>
			</div>
		</div>
		<div class="head-business-content">
			<div class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.info.performance"></label>
				<label class="head-green-lab">{{$t('home.sales')}}</label>
			</div>
			<div style="width: 1px; height: 80%; background-color: #FFFFFF;"></div>
			<div class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.wallet.lastPerformance"></label>
				<label class="head-green-lab">{{$t('home.todayBouns')}}</label>
			</div>
			<div style="width: 1px; height: 80%; background-color: #FFFFFF;"></div>
			<div class="head-business-info-content">
				<label class="head-red-lab" v-text="infoData.wallet.lastPerformance"></label>
				<label class="head-green-lab">{{$t('home.bouns')}}</label>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/head.css"
	
	export default {
		name: 'HomeHead',
		props: {
			infoData: {
				type: Object,
				default: () => {
					return {
						info:{}
					}
				}
			}
			
		},
		data:() => ({
			headBg : {
				height: "200px",
				backgroundImage: "url(" + require("../assets/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			},
		}),
		methods: {}
	}
</script>


