<template>
	<div class="bg-content">
		<SettingHead :infoData="infoData" style="height: 140px;"></SettingHead>
<!-- 		<router-link to="/moneyIndex" class="white-row-content fund-func-content" style="margin-top: 10px;">
			<div class="row-content">
				<img class="fund-func-img" :src="require('../assets/setting/buy-fund.png')"/>
				<label class="fund-func-lab">{{$t('setting.fund')}}</label>
			</div>
			<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
		</router-link> -->
		
		<router-link to="/orderList" class="white-row-content fund-func-content" style="margin-top: 10px;">
			<div class="row-content">
				<img class="fund-func-img" :src="require('../assets/setting/my-order.png')"/>
				<label class="fund-func-lab">{{$t('setting.orderList')}}</label>
			</div>
			<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
		</router-link>
		
		<router-link to="/myFundList" class="white-row-content fund-func-content">
			<div class="row-content">
				<img class="fund-func-img" :src="require('../assets/setting/my-fund.png')"/>
				<label class="fund-func-lab">{{$t('setting.myFundList')}}</label>
			</div>
			<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
		</router-link>
		
		<router-link to="/authStatus" class="white-row-content fund-func-content" style="margin-top: 10px;">
			<div class="row-content">
				<img class="fund-func-img" :src="require('../assets/setting/identity.png')"/>
				<label class="fund-func-lab">{{$t('setting.information')}}</label>
			</div>
			<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
		</router-link>
		
		<router-link to="/backup" class="white-row-content fund-func-content">
			<div class="row-content">
				<img class="fund-func-img" :src="require('../assets/setting/google-identity.png')"/>
				<label class="fund-func-lab">{{$t('setting.google')}}</label>
			</div>
			<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
		</router-link>
		
		<router-link to="/accountSetting" class="white-row-content fund-func-content">
			<div class="row-content">
				<img class="fund-func-img" :src="require('../assets/setting/account-setting.png')"/>
				<label class="fund-func-lab">{{$t('setting.account')}}</label>
			</div>
			<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
		</router-link>
		
		<!-- <router-link to="/addressList" class="white-row-content fund-func-content">
			<div class="row-content">
				<img class="fund-func-img" :src="require('../assets/setting/withdraw.png')"/>
				<label class="fund-func-lab">{{$t('setting.address')}}</label>
			</div>
			<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
		</router-link> -->
		
		<router-link to="/invite" class="white-row-content fund-func-content">
			<div class="row-content">
				<img class="fund-func-img" :src="require('../assets/setting/add-member.png')"/>
				<label class="fund-func-lab">{{$t('setting.invite')}}</label>
			</div>
			<i class="fa fa-chevron-right" style="color: #009245;" aria-hidden="true"></i>
		</router-link>
		
		<div class="white-row-content fund-func-content">
			<div class="row-content">
				<img class="fund-func-img" :src="require('../assets/setting/computer-version.png')"/>
				<label class="fund-func-lab">{{$t('setting.version')}}</label>
			</div>
			<label style="color: #888888; font-weight: bold;">V 1.0.0</label>
		</div>
		
		<div style="padding: 20px; 15px">
			<mt-button @click="logout" type="danger" style="height: 40px; border-radius: 5px; width: 100%;">{{$t('setting.logout')}}</mt-button>
		</div>
	</div>
</template>

<script>
	import SettingHead from '../components/SettingHead.vue'
	
	export default {
		name: 'Setting',
		props: {
			infoData: {
				type: Object,
				default: () => {
					return {
						info:{}
					}
				}
			}
			
		},
		components: {
			SettingHead
		},
		methods: {
			logout() {
				this.$store.commit('clearInfo');
				this.$router.push({
					name: "Login"
				});
			}
		}
	}
</script>

<style>
</style>
